<template>
  <v-container class="px-7 py-4 mk-course-details-form">
    <v-form ref="form" @input="(v) => $emit('update:valid', v)">
      <v-row class="mt-3">
        <v-col cols="5">
          <v-text-field
            v-model="formData.course_title"
            outlined
            :label="!$language ? 'Title' : 'কোর্স শিরোনাম'"
            :placeholder="
              !$language
                ? 'Enter your course title here...'
                : 'আপনার কোর্সের শিরোনাম লিখুন...'
            "
            height="45"
            :rules="rules.title"
            :readonly="readOnly"
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <m-text-field
            v-model="formData.course_key"
            outlined
            :label="!$language ? 'Course Key' : 'কোর্স কি'"
            :placeholder="
              !$language
                ? 'Enter your course key here...(i.e. CS-101)'
                : 'আপনার কোর্স কী লিখুন...(যেমন CS-101)'
            "
            height="45"
            :rules="rules.key"
            :readonly="readOnly"
          ></m-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-8 mb-2">
        <v-col cols="6" style="position: relative">
          <v-row no-gutters>
            <v-col cols="12">
              <v-select
                v-model="formData.course_type"
                :readonly="readOnly"
                outlined
                :label="!$language ? 'Course Type' : 'কোর্স টাইপ'"
                :items="course_type"
                :item-text="!$language ? 'en' : 'bn'"
                item-value="en"
              ></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1">
              <v-switch
                v-model="formData.quiz_enabled"
                :readonly="readOnly"
              ></v-switch>
            </v-col>
            <v-col class="d-flex align-center">
              <span style="padding-left: 3%">{{
                !$language
                  ? "Let Students take quiz"
                  : "শিক্ষার্থীদের জন্য কুইজ"
              }}</span>
            </v-col>
          </v-row>
          <!-- <v-switch
            v-model="formData.quiz_enabled"
            :readonly="readOnly"
            label="Let Students take quiz"
          ></v-switch> -->
        </v-col>

        <v-col>
          <span
            v-if="!$language"
            style="
              transform: translateY(-24px) scale(0.75);
              font-size: 16px;
              color: #7d7d7d;
              background-color: white;
              position: absolute;
              margin-top: 12px;
              margin-left: 6px;
              z-index: 1;
            "
            >{{ readOnly ? "Image" : "Add an image" }} (optional)</span
          >
          <span
            v-else
            style="
              transform: translateY(-24px) scale(0.75);
              font-size: 16px;
              color: #7d7d7d;
              background-color: white;
              position: absolute;
              margin-top: 12px;
              margin-left: 6px;
              z-index: 1;
            "
            >{{ readOnly ? "ছবি" : "ছবি যোগ করুন" }} (ঐচ্ছিক)</span
          >
          <v-row>
            <v-card
              outlined
              height="100%"
              width="65%"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                border-color: #c7c7c7;
              "
              :style="
                imageValidationMessage !== undefined
                  ? 'border-color: #ff5252'
                  : ''
              "
            >
              <v-col>
                <v-row justify="center">
                  <v-img
                    v-if="!isSelectedImage"
                    style="height: 250px; width: 40px"
                    :src="formData.image_url"
                  ></v-img>
                </v-row>
                <v-row v-if="!readOnly" justify="center">
                  <image-input
                    v-model="formData.image"
                    class="mt-3"
                    data-qa="question-image"
                    :rules="rules.imageRules"
                    @validation="valid_image"
                  >
                    <template v-slot:activator>
                      <div>
                        <m-button
                          v-if="!$language"
                          text
                          color="primary"
                          data-qa="question-image-button"
                          @click="isSelectedImage = true"
                        >
                          <v-icon left>image</v-icon>
                          {{ imageSelected ? "Change" : "Select an" }} image
                        </m-button>
                        <m-button
                          v-else
                          text
                          color="primary"
                          data-qa="question-image-button"
                          @click="isSelectedImage = true"
                        >
                          <v-icon left>image</v-icon>
                          ছবি
                          {{
                            imageSelected ? "পরিবর্তন করুন" : "নির্বাচন করুন"
                          }}
                        </m-button>
                      </div>
                    </template>
                  </image-input>
                </v-row>
                <v-img
                  v-else
                  style="height: 80%; width: 40px"
                  :src="formData.image_url"
                ></v-img>
              </v-col>
            </v-card>
          </v-row>
          <v-row
            ><span
              class="v-messages__message v-messages__wrapper v-messages pl-4 pt-2 error--text"
              >{{ imageValidationMessage }}</span
            ></v-row
          >
        </v-col>
      </v-row>
      <!-- <v-row class="mt-8 mb-1">
        <v-col cols="10" style="position: relative">
          <quill-editor
            ref="description"
            v-model="formData.course_description"
            :disabled="readOnly"
            :options="{
              ...editorOptions,
              placeholder: 'Enter your course description here...',
              scrollingContainer: $refs.description,
            }"
            :class="[
              'v-textarea',
              'description',
              showhideDescriptionWarningIndication,
            ]"
            @focus="onQuillFocus($event, 'description')"
            @blur="onQuillBlur($event, 'description')"
            @input="onQuillInput($event, 'description')"
          ></quill-editor>
          <div class="quill-label">About This Course</div>
          <div
            :class="[
              'v-text-field__details',
              showhideDescriptionWarningMessage,
            ]"
            style="padding-top: 8px"
          >
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  Need more than 40 and less than 8000 characters
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row> -->

      <v-row>
        <v-col cols="10">
          <tip-tap-editor
            v-model="formData.course_description"
            :label="!$language ? 'About this Course' : 'বিবরণ'"
            :place-holder="
              !$language
                ? 'Enter your course description here...'
                : 'আপনার কোর্সের বিবরণ লিখুন...'
            "
            :is-valid="!isDescriptionNotValid"
            :disabled="readOnly"
            allow-list-insertion
            allow-table-insertion
            @input="handlesDescriptionWarning"
          ></tip-tap-editor>
          <!-- :is-valid="!isSyllabusNotValid"
            @input="handlesSyllabusWarning" -->
          <div
            :class="[
              'v-text-field__details',
              showhideDescriptionWarningMessage,
            ]"
            class="pt-2 px-3"
          >
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  {{
                    !$language
                      ? "Need more than 40 and less than 60000 characters"
                      : "৪০ থেকে ৬০০০০ অক্ষরের মধ্যে সীমাবদ্ধ থাকুন"
                  }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1" class="d-flex justify-center align-center">
          <v-icon size="30">mdi-video</v-icon>
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-model="formData.about_video"
            outlined
            hide-details
            :readonly="readOnly"
            :label="!$language ? 'About Video' : 'কোর্সের ভিডিও'"
            :placeholder="
              !$language
                ? 'Add a video link for the about section'
                : 'একটি ভিডিও লিঙ্ক যোগ করুন'
            "
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="mb-4">
        <v-col cols="1" class="d-flex justify-center align-center">
          <v-icon size="30" color="#0099dc">mdi-facebook</v-icon>
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-model="formData.facebook_group"
            outlined
            hide-details
            :readonly="readOnly"
            :label="!$language ? 'Facebook Group' : 'ফেসবুক গ্রুপ'"
            :placeholder="
              !$language
                ? 'Add a link to your facebook group'
                : 'ফেসবুক গ্রুপের লিঙ্ক যোগ করুন'
            "
          >
          </v-text-field>
        </v-col>
      </v-row>

      <!-- <v-row class="mt-2 mb-2">
        <v-col cols="10" style="position: relative">
          <quill-editor
            ref="syllabus"
            v-model="formData.syllabus"
            :disabled="readOnly"
            :options="{
              ...editorOptions,
              placeholder: 'Enter your course syllabus here...',
              scrollingContainer: $refs.syllabus,
            }"
            :class="[
              'v-textarea',
              'description',
              showhideSyllabusWarningIndication,
            ]"
            @focus="onQuillFocus($event, 'syllabus')"
            @blur="onQuillBlur($event, 'syllabus')"
            @input="onQuillInput($event, 'syllabus')"
          ></quill-editor>
          <div class="quill-label">Course Outline</div>
          <div
            :class="['v-text-field__details', showhideSyllabusWarningMessage]"
            style="padding-top: 8px"
          >
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  Need more than 40 and less than 8000 characters
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="10">
          <tip-tap-editor
            v-model="formData.syllabus"
            :label="!$language ? 'Course Outline' : 'কোর্স সিলেবাস'"
            :place-holder="
              !$language
                ? 'Write your Course Outline here'
                : 'কোর্স সিলেবাস লিখুন'
            "
            :disabled="readOnly"
            :is-valid="!isSyllabusNotValid"
            allow-list-insertion
            allow-table-insertion
            @input="handlesSyllabusWarning"
          ></tip-tap-editor>
          <div
            :class="['v-text-field__details', showhideSyllabusWarningMessage]"
            class="pt-2 px-3"
          >
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  {{
                    !$language
                      ? "Need more than 40 and less than 60000 characters"
                      : "৪০ থেকে ৬০০০০ অক্ষরের মধ্যে সীমাবদ্ধ থাকুন"
                  }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <!--      <v-row>-->
      <!--        <v-col>-->
      <!--          <span-->
      <!--              style="transform: translateY(-24px) scale(0.75); font-size: 16px; color: #7d7d7d; background-color: white; position:absolute; margin-top: 12px; margin-left: 6px; z-index: 12;"-->
      <!--          >{{ "Syllabus" }}</span>-->
      <!--          <v-card-->
      <!--              outlined-->
      <!--              min-height="26vh"-->
      <!--              width="83%"-->
      <!--              style="border-color: #C7C7C7; padding-top: 10px"-->
      <!--          >-->
      <!--            <v-row-->
      <!--                v-for="(topic, i) in formData.syllabus_topics"-->
      <!--                :key="i"-->
      <!--                style="padding-left: 30px; max-height: 40px;"-->
      <!--            >-->
      <!--                <v-col style="max-width: 20px">-->
      <!--                  <v-icon size="12" class="mt-2">circle</v-icon>-->
      <!--                </v-col>-->
      <!--                <v-col cols="3">-->
      <!--                <v-text-field-->
      <!--                    dense-->
      <!--                    flat-->
      <!--                    solo-->
      <!--                    placeholder="Enter topic here..."-->
      <!--                    v-model="formData.syllabus_topics[i]"-->
      <!--                ></v-text-field>-->
      <!--                </v-col>-->
      <!--              <v-col cols="1" class="px-0"><v-btn-->
      <!--                  icon-->
      <!--                  style="left: -10px; margin-top: 3px;"-->
      <!--                  color="#FF5252"-->
      <!--                  @click="formData.syllabus_topics.splice(i, 1)"-->
      <!--              ><v-icon size="18">close</v-icon></v-btn></v-col>-->
      <!--            </v-row>-->

      <!--            <v-row style="padding-left: 30px; max-height: 60px;">-->
      <!--              <v-col>-->
      <!--                <div @click="addAnotherTopic()" style="cursor: pointer; text-decoration: underline; color: #0099dc; font-size: 12px; margin-top: 10px;">-->
      <!--                  Add Another-->
      <!--                </div>-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--          </v-card>-->
      <!--        </v-col>-->
      <!--      </v-row>-->

      <v-row class="mt-8 mb-2">
        <v-col cols="5" style="position: relative">
          <v-select
            v-model="formData.category"
            :readonly="readOnly"
            :rules="rules.category"
            outlined
            :label="!$language ? 'Course Category..' : 'কোর্স ক্যাটাগরি'"
            :items="categoryList"
            item-value="en"
            :item-text="!$language ? 'en' : 'bn'"
            @change="clearSubcategory"
          ></v-select>
        </v-col>
        <v-col v-if="!(formData.category == 'Others')" cols="5">
          <v-select
            v-model="formData.subcategories"
            :readonly="readOnly"
            :rules="rules.subcategories"
            outlined
            multiple
            :label="!$language ? 'Course Sub-Category' : 'কোর্স সাব ক্যাটাগরি'"
            :items="subCategoryList"
            item-value="en"
            :item-text="!$language ? 'en' : 'bn'"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-show="formData.category == 'Others'">
        <v-col cols="5" class="pt-0 pb-5">
          <v-text-field
            v-model="formData.additionalSubCategory"
            :disabled="readOnly"
            outlined
            hide-details
            :label="!$language ? 'Describe Category' : 'ক্যাটাগরির বিবরণ'"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span
            :style="`transform: translateY(-24px) scale(0.75);
              font-size: 16px;
              color: ${courseMaterialStyle};
              background-color: white;
              position: absolute;
              margin-top: 12px;
              margin-left: 6px;
              z-index: 1;`"
            >{{ !$language ? "This Course Includes" : "এই কোর্সে আছে" }}</span
          >
          <span
            :style="`transform: translateY(-24px) scale(0.75);
              font-size: 16px;
              color: ${courseMaterialStyle};
              background-color: white;
              position: absolute;
              margin-top: 12px;
              margin-left: 6px;
              z-index: 1;`"
            >{{ !$language ? "This Course Includes" : "এই কোর্সে আছে" }}</span
          >
          <v-card
            flat
            outlined
            min-height="38vh"
            width="83%"
            :style="`border-color: ${courseMaterialStyle}; padding-top: 3%; padding-left: 3%`"
          >
            <v-row style="padding-left: 30px; max-height: 40px">
              <v-col style="max-width: 13%">
                <input
                  id="mk-course-details-form-page-input-video-lecture-count"
                  v-model="formData.course_materials.video_lectures"
                  placeholder="00"
                  style="width: 70px; min-height: 35px"
                  class="course-materials-box"
                  type="number"
                  :class="{ inert: readOnly }"
                  min="0"
                  @input="
                    adjustInput(
                      'mk-course-details-form-page-input-video-lecture-count'
                    )
                  "
                />
              </v-col>
              <v-col
                class="px-0 pt-5 course-materials-text"
                style="max-width: 12%; left: 30px"
              >
                {{ !$language ? "Video Lectures" : "ভিডিও লেকচার" }}
              </v-col>

              <v-col cols="1"></v-col>

              <v-col style="max-width: 13%">
                <input
                  id="mk-course-details-form-page-input-live-class-count"
                  v-model="formData.course_materials.live_classes"
                  placeholder="00"
                  style="width: 70px; min-height: 35px"
                  class="course-materials-box"
                  type="number"
                  min="0"
                  :class="{ inert: readOnly }"
                  @input="
                    adjustInput(
                      'mk-course-details-form-page-input-live-class-count'
                    )
                  "
                />
              </v-col>
              <v-col
                class="px-0 pt-5 course-materials-text"
                style="max-width: 12%; left: 30px"
              >
                {{ !$language ? "Live Class(es)" : "লাইভ ক্লাস" }}
              </v-col>

              <v-col cols="1"></v-col>

              <v-col style="max-width: 13%">
                <input
                  id="mk-course-details-form-page-input-quiz-count"
                  v-model="formData.course_materials.quizzes"
                  placeholder="00"
                  style="width: 70px; min-height: 35px"
                  class="course-materials-box"
                  type="number"
                  min="0"
                  :class="{ inert: readOnly }"
                  @input="
                    adjustInput('mk-course-details-form-page-input-quiz-count')
                  "
                />
              </v-col>
              <v-col
                class="px-0 pt-5 course-materials-text"
                style="max-width: 12%; left: 30px"
              >
                {{ !$language ? "Quiz(es)" : "কুইজ" }}
              </v-col>
            </v-row>

            <v-row style="padding-left: 30px; max-height: 40px" class="mt-4">
              <v-col style="max-width: 13%">
                <input
                  id="mk-course-details-form-page-input-lecture-count"
                  v-model="formData.course_materials.lecture_notes"
                  placeholder="00"
                  style="width: 70px; min-height: 35px"
                  class="course-materials-box"
                  type="number"
                  min="0"
                  :class="{ inert: readOnly }"
                  @input="
                    adjustInput(
                      'mk-course-details-form-page-input-lecture-count'
                    )
                  "
                />
              </v-col>
              <v-col
                class="px-0 pt-5 course-materials-text"
                style="max-width: 12%; left: 30px"
              >
                {{ !$language ? "Lecture Note(s)" : "লেকচার নোট" }}
              </v-col>

              <v-col cols="1"></v-col>

              <v-col style="max-width: 13%">
                <input
                  id="mk-course-details-form-page-input-project-count"
                  v-model="formData.course_materials.projects"
                  placeholder="00"
                  style="width: 70px; min-height: 35px"
                  class="course-materials-box"
                  type="number"
                  min="0"
                  :class="{ inert: readOnly }"
                  @input="
                    adjustInput(
                      'mk-course-details-form-page-input-project-count'
                    )
                  "
                />
              </v-col>
              <v-col
                class="px-0 pt-5 course-materials-text"
                style="max-width: 12%; left: 30px"
              >
                {{ !$language ? "Project(s)" : "প্রজেক্ট" }}
              </v-col>

              <v-col cols="1"></v-col>
            </v-row>

            <v-row style="padding-left: 30px" class="mt-4">
              <v-col style="max-width: 13%">
                <input
                  id="mk-course-details-form-page-input-assignment-count"
                  v-model="formData.course_materials.assignments"
                  placeholder="00"
                  style="width: 70px; min-height: 35px"
                  class="course-materials-box"
                  type="number"
                  min="0"
                  :class="{ inert: readOnly }"
                  @input="
                    adjustInput(
                      'mk-course-details-form-page-input-assignment-count'
                    )
                  "
                />
              </v-col>
              <v-col
                class="px-0 pt-5 course-materials-text"
                style="max-width: 12%; left: 30px"
              >
                {{ !$language ? "Assignment(s)" : "এসাইনমেন্ট" }}
              </v-col>

              <v-col cols="1"></v-col>

              <v-col style="max-width: 13%">
                <input
                  id="mk-course-details-form-page-input-exam-count"
                  v-model="formData.course_materials.exams"
                  placeholder="00"
                  style="width: 70px; min-height: 35px"
                  class="course-materials-box"
                  type="number"
                  min="0"
                  :class="{ inert: readOnly }"
                  @input="
                    adjustInput('mk-course-details-form-page-input-exam-count')
                  "
                />
              </v-col>
              <v-col
                class="px-0 pt-5 course-materials-text"
                style="max-width: 12%; left: 30px"
              >
                {{ !$language ? "Exam(s)" : "পরীক্ষা" }}
              </v-col>
            </v-row>
          </v-card>
          <span
            v-if="
              shouldShowCourseMaterialAlert &&
              !courseMaterialValid &&
              formData.course_type == 'Public'
            "
            style="font-size: 12px; color: #ff5252"
            class="pt-2 px-3"
            >{{
              !$language
                ? "If course is public, course materials count must be greater than 0"
                : "পাবলিক কোর্সের জন্য কোর্স সংখ্যা নূন্যতম ১ হতে হবে"
            }}</span
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ImageInput from "~ecf/components/ImageInput";

import TipTapEditor from "@ecf/global/components/TipTapEditor.vue";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "CourseDetailsFormP",
  components: { ImageInput, TipTapEditor },
  model: {
    prop: "courseDetails",
  },
  mixins: [FieldValidations],
  props: {
    courseDetails: {
      type: Object,
      default: () => ({}),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      required: true,
    },
    shouldShowCourseMaterialAlert: {
      type: Boolean,
      default: false,
    },
    shouldAutoFocus: {
      type: Boolean,
      default: false,
    },
    isClickSaveButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    if (this.readOnly === false) {
      return {
        showGeneralMessage: false,
        imageValidationMessage: undefined,
        course_type: [
          { en: "Public", bn: "পাবলিক" },
          { en: "Private", bn: "প্রাইভেট" },
        ],
        temp: "The brown fox jumped over the lazy dog...",
        categoryList: [
          { en: "SSC", bn: "এসএসসি" },
          { en: "HSC", bn: "এইচএসসি" },
          {
            en: "University Admission",
            bn: "বিশ্ববিদ্যালয় ভর্তি",
          },
          { en: "Job Preparation", bn: "চাকরির প্রস্তুতি" },
          {
            en: "Career Skills",
            bn: "ক্যারিয়ার দক্ষতা",
          },
          { en: "Corporate Skills", bn: "কর্পোরেট দক্ষতা" },
          {
            en: "Freelancing Skills",
            bn: "ফ্রিল্যান্সিং দক্ষতা",
          },
          {
            en: "Art Skills",
            bn: "শিল্প দক্ষতা",
          },
          { en: "O Level", bn: "ও লেভেল" },
          { en: "A Level", bn: "এ লেভেল" },
          { en: "Others", bn: "অন্যান্য" },
        ],
        shouldShowDescriptionWarning: false,
        shouldShowSyllabusWarning: false,
        aboutCoursePlaceholderEN: "Enter your course description here...",
        aboutCoursePlaceholderBN: "আপনার কোর্সের বিবরণ লিখুন...",
        formData: {
          course_type: "Public",
          syllabus_topics: [""],
          course_materials: {
            video_lectures: 0,
            live_classes: 0,
            quizzes: 0,
            lecture_notes: 0,
            projects: 0,
            assignments: 0,
            exams: 0,
          },
          ...this.courseDetails,
        },
        f1: false,
        f2: false,
        somedata: "<p>i am free</p>",
        es: "",
        editorOptions: {
          modules: {
            toolbar: [
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
            ],
          },
        },
        additionalSubCategory: "",
      };
    } else {
      return {
        imageValidationMessage: undefined,
        isSelectedImage: false,
        course_type: [
          { en: "Public", bn: "পাবলিক" },
          { en: "Private", bn: "প্রাইভেট" },
        ],
        temp: "The brown fox jumped over the lazy dog...",
        categoryList: [
          { en: "SSC", bn: "এসএসসি" },
          { en: "HSC", bn: "এইচএসসি" },
          {
            en: "University Admission",
            bn: "বিশ্ববিদ্যালয় ভর্তি",
          },
          { en: "Job Preparation", bn: "চাকরির প্রস্তুতি" },
          {
            en: "Career Skills",
            bn: "ক্যারিয়ার দক্ষতা",
          },
          { en: "Corporate Skills", bn: "কর্পোরেট দক্ষতা" },
          {
            en: "Freelancing Skills",
            bn: "ফ্রিল্যান্সিং দক্ষতা",
          },
          {
            en: "Art Skills",
            bn: "শিল্প দক্ষতা",
          },
          { en: "O Level", bn: "ও লেভেল" },
          { en: "A Level", bn: "এ লেভেল" },
          { en: "Others", bn: "অন্যান্য" },
        ],
        formData: { ...this.courseDetails, additionalSubCategory: "" },
        editorOptions: {
          modules: {
            toolbar: false,
          },
        },
        somedata: "<p>i am free</p>",
        es: "",
        shouleAutoFocus: false,
        shouldShowDescriptionWarning: false,
        shouldShowSyllabusWarning: false,
      };
    }
  },

  computed: {
    courseMaterialStyle() {
      return this.shouldShowCourseMaterialAlert &&
        !this.courseMaterialValid &&
        this.formData.course_type == "Public"
        ? "#ff1744"
        : "#c7c7c7";
    },
    showhideDescriptionWarningIndication() {
      return this.shouldShowDescriptionWarning ? "show-warning-indication" : "";
    },
    showhideDescriptionWarningMessage() {
      return this.shouldShowDescriptionWarning
        ? "show-warning-message"
        : "hide-warning-message";
    },
    showhideSyllabusWarningIndication() {
      return this.shouldShowSyllabusWarning ? "show-warning-indication" : "";
    },
    showhideSyllabusWarningMessage() {
      return this.shouldShowSyllabusWarning
        ? "show-warning-message"
        : "hide-warning-message";
    },
    isDescriptionNotValid() {
      return (
        this.formData.course_description == undefined ||
        this.removeHtmlTag(this.formData.course_description).length < 40 ||
        this.removeHtmlTag(this.formData.course_description).length > 60000
      );
    },
    isSyllabusNotValid() {
      return (
        this.formData.syllabus == undefined ||
        this.removeHtmlTag(this.formData.syllabus).length < 40 ||
        this.removeHtmlTag(this.formData.syllabus).length > 60000
      );
    },
    courseMaterialValid() {
      let materialCount = this.courseMaterialCount;
      if (materialCount === 0) {
        return false;
      }
      for (let i = 0; i < materialCount.length; i++) {
        if (materialCount[i] !== "0") {
          return true;
        }
      }
      return false;
    },
    courseMaterialCount() {
      let materials = this.formData.course_materials;
      return (
        materials.video_lectures +
        materials.live_classes +
        materials.quizzes +
        materials.lecture_notes +
        materials.projects +
        materials.assignments +
        materials.exams
      );
    },

    subCategoryList() {
      if (
        !("category" in this.formData) ||
        this.formData.category === undefined
      ) {
        return [];
      } else if (this.formData.category === "SSC") {
        return [
          { en: "Bangla", bn: "বাংলা" },
          { en: "English", bn: "ইংরেজি" },
          { en: "Mathematics", bn: "গণিত" },
          { en: "Religion", bn: "ধর্ম" },
          { en: "ICT", bn: "আইসিটি" },
          { en: "Physics", bn: "পদার্থবিদ্যা" },
          { en: "Chemistry", bn: "রসায়ন" },
          { en: "Biology", bn: "জীববিদ্যা" },
          { en: "Higher Math", bn: "উচ্চতর গণিত" },
          { en: "Accounting", bn: "হিসাববিজ্ঞান" },
          { en: "Finance & Banking", bn: "ফিন্যান্স এবং ব্যাংকিং" },
          { en: "Business Entrepreneurship", bn: "ব্যবসা উদ্যোক্তা" },
          { en: "Agricultural Studies", bn: "কৃষি শিক্ষা" },
          { en: "General Science", bn: "সাধারন বিজ্ঞান" },
          {
            en: "Bangladesh and Global Studies",
            bn: "বাংলাদেশ এবং গ্লোবাল স্টাডিজ",
          },
        ];
      } else if (this.formData.category === "HSC") {
        return [
          { en: "Bangla", bn: "বাংলা" },
          { en: "English", bn: "ইংরেজি" },
          { en: "Mathematics", bn: "গণিত" },
          { en: "Religion", bn: "ধর্ম" },
          { en: "ICT", bn: "আইসিটি" },
          { en: "Physics", bn: "পদার্থবিদ্যা" },
          { en: "Chemistry", bn: "রসায়ন" },
          { en: "Biology", bn: "জীববিদ্যা" },
          { en: "Higher Math", bn: "উচ্চতর গণিত" },
          { en: "Accounting", bn: "হিসাববিজ্ঞান" },
          { en: "Finance & Banking", bn: "ফিন্যান্স এবং ব্যাংকিং" },
          { en: "Business Entrepreneurship", bn: "ব্যবসা উদ্যোক্তা" },
          { en: "Agricultural Studies", bn: "কৃষি শিক্ষা" },
          { en: "General Science", bn: "সাধারন বিজ্ঞান" },
          {
            en: "Bangladesh and Global Studies",
            bn: "বাংলাদেশ এবং গ্লোবাল স্টাডিজ",
          },
        ];
      } else if (this.formData.category === "University Admission") {
        return [
          {
            en: "Dhaka University- A unit, GST C unit",
            bn: "ঢাকা বিশ্ববিদ্যালয়- এ ইউনিট, জিএসটি সি ইউনিট",
          },
          {
            en: "Dhaka University B unit, GST A unit",
            bn: "ঢাকা বিশ্ববিদ্যালয়- বি ইউনিট, জিএসটি এ ইউনিট",
          },
          {
            en: "Dhaka University- C unit, GST B unit",
            bn: "ঢাকা বিশ্ববিদ্যালয়- সি ইউনিট, জিএসটি বি ইউনিট",
          },
          {
            en: "Jahangirnagar University- A Unit",
            bn: "জাহাঙ্গীরনগর বিশ্ববিদ্যালয়- এ ইউনিট",
          },
          {
            en: "Jahangirnagar University- B Unit",
            bn: "জাহাঙ্গীরনগর বিশ্ববিদ্যালয়- বি ইউনিট",
          },
          {
            en: "Jahangirnagar University- C Unit",
            bn: "জাহাঙ্গীরনগর বিশ্ববিদ্যালয়- সি ইউনিট",
          },
          {
            en: "Jahangirnagar University- D Unit",
            bn: "জাহাঙ্গীরনগর বিশ্ববিদ্যালয়- ডি ইউনিট",
          },
          {
            en: "Jahangirnagar University- E Unit",
            bn: "জাহাঙ্গীরনগর বিশ্ববিদ্যালয়- ই ইউনিট",
          },
        ];
      } else if (this.formData.category === "Job Preparation") {
        return [
          {
            en: "BCS and Autonomous Industries",
            bn: "বিসিএস এবং স্বায়ত্তশাসিত শিল্প",
          },
          { en: "Banks and NBFI", bn: "ব্যাংক এবং এনবিএফআই" },
          {
            en: "Armed Force and Defence",
            bn: "সশস্ত্র বাহিনী এবং প্রতিরক্ষা",
          },
        ];
      } else if (this.formData.category === "Career Skills") {
        return [
          { en: "IELTS", bn: "আইইএলটিএস" },
          {
            en: "Microsoft Suite (WORD, EXCEL, Powerpoint and others)",
            bn: "মাইক্রোসফট স্যুট (ওয়ার্ড, এক্সেল, পাওয়ার পয়েন্ট  এবং অন্যান্য)",
          },
          { en: "English Basic skills", bn: "ইংরেজির মৌলিক দক্ষতা" },
          { en: "Foreign Language Course", bn: "বিদেশী ভাষার কোর্স" },
        ];
      } else if (this.formData.category === "Corporate Skills") {
        return [
          { en: "Negotiation", bn: "নেগোসিয়েশন দক্ষতা" },
          { en: "Leadership", bn: "নেতৃত্বের দক্ষতা" },
          { en: "Sales", bn: "সেলস" },
          { en: "HR Management", bn: "এইচআর ম্যানেজমেন্ট" },
          { en: "Digital Marketing", bn: "ডিজিটাল মার্কেটিং" },
          { en: "Motivation", bn: "অনুপ্রেরণামূলক" },
        ];
      } else if (this.formData.category === "Freelancing Skills") {
        return [
          { en: "Digital Marketing", bn: "ডিজিটাল মার্কেটিং" },
          { en: "Web Design", bn: "ওয়েব ডিজাইন" },
          { en: "Programming and Coding", bn: "প্রোগ্রামিং এবং কোডিং" },
          {
            en: "Video editing, Animation and Motion Graphics",
            bn: "ভিডিও এডিটিং, অ্যানিমেশন এবং মোশন গ্রাফিক্স",
          },
          { en: "Photography", bn: "ফটোগ্রাফি" },
          { en: "Graphics Designing", bn: "গ্রাফিক্স ডিজাইনিং" },
        ];
      } else if (this.formData.category === "Art Skills") {
        return [
          { en: "Music", bn: "সঙ্গীত" },
          { en: "Dancing", bn: "নৃত্য" },
          { en: "Painting", bn: "পেইন্টিং" },
        ];
      } else if (this.formData.category === "O Level") {
        return [
          { en: "Mathematics", bn: "গণিত" },
          { en: "English Literature", bn: "ইংরেজি সাহিত্য" },
          { en: "History", bn: "ইতিহাস" },
          { en: "Geography", bn: "ভূগোল" },
          { en: "Physics", bn: "পদার্থবিদ্যা" },
          { en: "Chemistry", bn: "রসায়ন" },
          { en: "Biology", bn: "জীববিদ্যা" },
          { en: "Combined Science", bn: "সম্মিলিত বিজ্ঞান" },
          { en: "Business Studies", bn: "ব্যবসায় শিক্ষা" },
          { en: "Enterprise", bn: "এন্টারপ্রাইজ" },
          { en: "Environment Management", bn: "পরিবেশ ব্যবস্থাপনা" },
          { en: "Economics", bn: "অর্থনীতি" },
          { en: "Accounting", bn: "হিসাববিজ্ঞান" },
          { en: "Bangladesh Studies", bn: "বাংলাদেশ স্টাডিজ" },
          { en: "Computer Science", bn: "কম্পিউটার বিজ্ঞান" },
          { en: "Art & Design", bn: "আর্ট এবং ডিজাইন" },
        ];
      } else if (this.formData.category === "A Level") {
        return [
          { en: "Economics", bn: "অর্থনীতি" },
          { en: "Physics", bn: "পদার্থবিদ্যা" },
          { en: "Chemistry", bn: "রসায়ন" },
          { en: "Business", bn: "ব্যবসা" },
          { en: "Biology", bn: "জীববিদ্যা" },
          { en: "Accounting", bn: "হিসাববিজ্ঞান" },
          { en: "Computer Science", bn: "কম্পিউটার বিজ্ঞান" },
          { en: "Mathematics", bn: "গণিত" },
          { en: "Law", bn: "আইন" },
          { en: "Psychology", bn: "মনোবিজ্ঞান" },
          { en: "English", bn: "ইংরেজি" },
        ];
      } else {
        return [];
      }
    },
    rules() {
      return {
        description: [
          (v) => this.minLength(v, 30, "description"),
          (v) => this.maxLength(v, 8196, "description"),
        ],
        title: [
          this.required,
          (v) => this.minLength(v, 6, "title"),
          (v) => this.maxLength(v, 63, "title"),
        ],
        key: [
          this.required,
          (v) => this.minLength(v, 5, "key"),
          (v) => this.maxLength(v, 8, "key"),
        ],
        category: this.readOnly ? [] : [v=>this.required(v)],
        subcategories: this.readOnly ? [] : [v=>this.ruleSubCategory(v)],
        imageRules: [
          (v) => this.fileSizeLessThanOrEqual(v, 2 * 1024 * 1024, "Image size"),
        ],
      };
    },
    imageSelected() {
      if (
        "image" in this.formData &&
        "imageURL" in this.formData.image &&
        this.formData.image.imageURL !== null
      ) {
        return true;
      } else return false;
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(value) {
        this.$emit("input", value);
      },
      immediate: true,
    },
    isClickSaveButton: {
      handler(value) {
        if (value) {
          this.showGeneralMessage = true;
        }
      },
    },
    shouldAutoFocus: {
      handler(newValue) {
        if (newValue) {
          Array.from(document.getElementsByClassName("v-label"))
            .filter((field) => {
              return (
                field.innerText != "Let Students take quiz" &&
                field.innerText != "Course Type"
              );
            })
            .forEach((field) => {
              if (!field.classList.value.includes("error--text")) {
                if (
                  field.parentElement.parentElement.parentElement.children[1]
                    .innerText == "" &&
                  field.parentElement.parentElement.children[1].children[1] &&
                  field.parentElement.parentElement.children[1].children[1]
                    .value == ""
                ) {
                  field.classList.add("error--text");
                } else if (
                  field.parentElement.parentElement.parentElement.children[1]
                    .innerText == "" &&
                  field.parentElement.parentElement.parentElement.children[0]
                    .children[1].children[3] &&
                  field.parentElement.parentElement.parentElement.children[0]
                    .children[1].children[3].value == ""
                ) {
                  field.classList.add("error--text");
                }
              }
            });

          if (this.isDescriptionNotValid) {
            Array.from(document.getElementsByClassName("quill-label"))
              .filter((field) => {
                return field.innerText == "Description";
              })
              .forEach((field) => {
                if (!field.classList.value.includes("error--text")) {
                  field.classList.add("error--text");
                }
              });
          }
          if (this.isSyllabusNotValid) {
            Array.from(document.getElementsByClassName("quill-label"))
              .filter((field) => {
                return field.innerText == "Syllabus";
              })
              .forEach((field) => {
                if (!field.classList.value.includes("error--text")) {
                  field.classList.add("error--text");
                }
              });
          }
        }
      },
    },

    isSyllabusNotValid: {
      handler(newValue) {
        Array.from(document.getElementsByClassName("quill-label"))
          .filter((field) => {
            return field.innerText == "Syllabus";
          })
          .forEach((field) => {
            if (!field.classList.value.includes("error--text") && newValue) {
              field.classList.add("error--text");
            } else {
              field.classList.remove("error--text");
            }
          });
      },
    },

    isDescriptionNotValid: {
      handler(newValue) {
        Array.from(document.getElementsByClassName("quill-label"))
          .filter((field) => {
            return field.innerText == "Description";
          })
          .forEach((field) => {
            if (!field.classList.value.includes("error--text") && newValue) {
              field.classList.add("error--text");
            } else {
              field.classList.remove("error--text");
            }
          });
      },
    },
  },
  created() {
    this.courseDetails.image = { imageURL: undefined, file: undefined };
    if (
      this.formData.category === "Others" &&
      this.formData.subcategories.length > 0
    )
      this.formData.additionalSubCategory = this.formData.subcategories[0];
  },
  mounted() {},
  methods: {
    ruleSubCategory(v){
      if(this.formData.category) return this.minLength(v, 1, "Subcategory");
      else true;
    },
    closeMessage() {
      this.showGeneralMessage = false;
      this.$emit("closeAlertMessage");
    },
    valid_image(value) {
      this.imageValidationMessage = value;
    },
    addAnotherTopic() {
      this.formData.syllabus_topics.push("");
    },
    onQuillFocus($event, ref) {
      this.$refs[ref].$el.classList.add("quill-focused");
    },
    adjustInput(id) {
      let value = parseInt(document.getElementById(id).value);
      if (isNaN(value)) {
        document.getElementById(id).value = 0;
      } else {
        document.getElementById(id).value = value;
      }
    },
    onQuillBlur($event, ref) {
      if (ref == "description") {
        this.shouldShowDescriptionWarning = this.isDescriptionNotValid;
      } else if (ref == "syllabus") {
        this.shouldShowSyllabusWarning = this.isSyllabusNotValid;
      }
      this.$refs[ref].$el.classList.remove("quill-focused");
    },
    onQuillInput($event, ref) {
      if (ref == "description") {
        this.shouldShowDescriptionWarning =
          this.formData.course_description == undefined ||
          this.removeHtmlTag(this.formData.course_description).length < 40 ||
          this.removeHtmlTag(this.formData.course_description).length > 8000;
        if (!this.shouldShowDescriptionWarning)
          this.$refs[ref].$el.classList.add("quill-focused");
      } else if (ref == "syllabus") {
        this.shouldShowSyllabusWarning =
          this.formData.syllabus == undefined ||
          this.removeHtmlTag(this.formData.syllabus).length < 40 ||
          this.removeHtmlTag(this.formData.syllabus).length > 8000;
        if (!this.shouldShowSyllabusWarning)
          this.$refs[ref].$el.classList.add("quill-focused");
      }
    },

    handlesDescriptionWarning() {
      this.shouldShowDescriptionWarning =
        this.formData.course_description == undefined ||
        this.removeHtmlTag(this.formData.course_description).length < 40 ||
        this.removeHtmlTag(this.formData.course_description).length > 60000;
    },

    handlesSyllabusWarning() {
      this.shouldShowSyllabusWarning =
        this.formData.syllabus == undefined ||
        this.removeHtmlTag(this.formData.syllabus).length < 40 ||
        this.removeHtmlTag(this.formData.syllabus).length > 60000;
    },

    removeHtmlTag(htmlData) {
      let div = document.createElement("div");
      div.innerHTML = htmlData;
      return div.textContent || div.innerText || "";
    },

    clearSubcategory(payload) {
      this.$delete(this.formData, "subcategories");
    },

    async validate() {
      await this.$refs.form.validate();
      this.handlesDescriptionWarning();
      this.handlesSyllabusWarning();
    },
  },
};
</script>

<style lang="scss">
.course-materials-text {
  font-size: 12px;
  color: #8e8e8e;
}

.course-materials-box {
  border: solid #0099dc 1px;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
}

.v-input__slot > .fieldset {
  color: red !important;
}

//.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
//  color: #0099dc !important;
//}

.mk-course-details-form.v-text-field--filled.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.mk-course-details-form.v-text-field--filled.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.mk-course-details-form.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.mk-course-details-form.v-text-field--full-width.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.mk-course-details-form.v-text-field--full-width.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.mk-course-details-form.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.mk-course-details-form.v-text-field--outlined.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.mk-course-details-form.v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.mk-course-details-form.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  min-height: 35px;
}

.mk-course-details-form.v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
  min-height: 35px;
}

.ql-editor.ql-blank::before {
  font-style: normal;
  font-size: 16px;
}

.how-it-works .ql-editor,
.description .ql-editor {
  height: auto;
  min-height: 150px;
}

.ql-toolbar.ql-snow {
  border-radius: 8px 8px 0px 0px !important;
  border: 1px solid #c7c7c7 !important;
  border-bottom: 0px !important;
}

.ql-container.ql-snow {
  border-radius: 0px 0px 8px 8px !important;
  border: 1px solid #c7c7c7 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 16px;
}

.quill-label {
  position: absolute;
  top: 0px;
  left: 20px;
  background-color: white;
  padding: 4px 6px 0px;
  font-size: 0.7rem;
  color: #787878;
}

.quill-focused {
  .ql-toolbar {
    border-top-color: #0089cb !important;
    border-right-color: #0089cb !important;
    border-left-color: #0089cb !important;
  }

  .ql-container {
    border-color: #0089cb !important;
  }

  & + .quill-label {
    color: #0089cb !important;
  }
}

.show-warning-indication {
  .ql-toolbar {
    border-top-color: #ff5e5e !important;
    border-right-color: #ff5e5e !important;
    border-left-color: #ff5e5e !important;
  }

  .ql-container {
    border-color: #ff5e5e !important;
  }

  & + .quill-label {
    color: #ff5e5e !important;
  }
}

.hide-warning-message {
  visibility: hidden;
}

.show-warning-message {
  visibility: visible;
}
</style>
