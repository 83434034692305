var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-7 py-4 mk-course-details-form" },
    [
      _c(
        "v-form",
        { ref: "form", on: { input: (v) => _vm.$emit("update:valid", v) } },
        [
          _c(
            "v-row",
            { staticClass: "mt-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: !_vm.$language ? "Title" : "কোর্স শিরোনাম",
                      placeholder: !_vm.$language
                        ? "Enter your course title here..."
                        : "আপনার কোর্সের শিরোনাম লিখুন...",
                      height: "45",
                      rules: _vm.rules.title,
                      readonly: _vm.readOnly,
                    },
                    model: {
                      value: _vm.formData.course_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "course_title", $$v)
                      },
                      expression: "formData.course_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("m-text-field", {
                    attrs: {
                      outlined: "",
                      label: !_vm.$language ? "Course Key" : "কোর্স কি",
                      placeholder: !_vm.$language
                        ? "Enter your course key here...(i.e. CS-101)"
                        : "আপনার কোর্স কী লিখুন...(যেমন CS-101)",
                      height: "45",
                      rules: _vm.rules.key,
                      readonly: _vm.readOnly,
                    },
                    model: {
                      value: _vm.formData.course_key,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "course_key", $$v)
                      },
                      expression: "formData.course_key",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-8 mb-2" },
            [
              _c(
                "v-col",
                { staticStyle: { position: "relative" }, attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              readonly: _vm.readOnly,
                              outlined: "",
                              label: !_vm.$language
                                ? "Course Type"
                                : "কোর্স টাইপ",
                              items: _vm.course_type,
                              "item-text": !_vm.$language ? "en" : "bn",
                              "item-value": "en",
                            },
                            model: {
                              value: _vm.formData.course_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "course_type", $$v)
                              },
                              expression: "formData.course_type",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [
                          _c("v-switch", {
                            attrs: { readonly: _vm.readOnly },
                            model: {
                              value: _vm.formData.quiz_enabled,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "quiz_enabled", $$v)
                              },
                              expression: "formData.quiz_enabled",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-col", { staticClass: "d-flex align-center" }, [
                        _c("span", { staticStyle: { "padding-left": "3%" } }, [
                          _vm._v(
                            _vm._s(
                              !_vm.$language
                                ? "Let Students take quiz"
                                : "শিক্ষার্থীদের জন্য কুইজ"
                            )
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  !_vm.$language
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            transform: "translateY(-24px) scale(0.75)",
                            "font-size": "16px",
                            color: "#7d7d7d",
                            "background-color": "white",
                            position: "absolute",
                            "margin-top": "12px",
                            "margin-left": "6px",
                            "z-index": "1",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.readOnly ? "Image" : "Add an image") +
                              " (optional)"
                          ),
                        ]
                      )
                    : _c(
                        "span",
                        {
                          staticStyle: {
                            transform: "translateY(-24px) scale(0.75)",
                            "font-size": "16px",
                            color: "#7d7d7d",
                            "background-color": "white",
                            position: "absolute",
                            "margin-top": "12px",
                            "margin-left": "6px",
                            "z-index": "1",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.readOnly ? "ছবি" : "ছবি যোগ করুন") +
                              " (ঐচ্ছিক)"
                          ),
                        ]
                      ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-card",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                            "align-items": "center",
                            "border-color": "#c7c7c7",
                          },
                          style:
                            _vm.imageValidationMessage !== undefined
                              ? "border-color: #ff5252"
                              : "",
                          attrs: { outlined: "", height: "100%", width: "65%" },
                        },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  !_vm.isSelectedImage
                                    ? _c("v-img", {
                                        staticStyle: {
                                          height: "250px",
                                          width: "40px",
                                        },
                                        attrs: { src: _vm.formData.image_url },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              !_vm.readOnly
                                ? _c(
                                    "v-row",
                                    { attrs: { justify: "center" } },
                                    [
                                      _c("image-input", {
                                        staticClass: "mt-3",
                                        attrs: {
                                          "data-qa": "question-image",
                                          rules: _vm.rules.imageRules,
                                        },
                                        on: { validation: _vm.valid_image },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    [
                                                      !_vm.$language
                                                        ? _c(
                                                            "m-button",
                                                            {
                                                              attrs: {
                                                                text: "",
                                                                color:
                                                                  "primary",
                                                                "data-qa":
                                                                  "question-image-button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.isSelectedImage = true
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "image"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.imageSelected
                                                                      ? "Change"
                                                                      : "Select an"
                                                                  ) +
                                                                  " image "
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "m-button",
                                                            {
                                                              attrs: {
                                                                text: "",
                                                                color:
                                                                  "primary",
                                                                "data-qa":
                                                                  "question-image-button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.isSelectedImage = true
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "image"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " ছবি " +
                                                                  _vm._s(
                                                                    _vm.imageSelected
                                                                      ? "পরিবর্তন করুন"
                                                                      : "নির্বাচন করুন"
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          568029194
                                        ),
                                        model: {
                                          value: _vm.formData.image,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "image", $$v)
                                          },
                                          expression: "formData.image",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("v-img", {
                                    staticStyle: {
                                      height: "80%",
                                      width: "40px",
                                    },
                                    attrs: { src: _vm.formData.image_url },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "v-messages__message v-messages__wrapper v-messages pl-4 pt-2 error--text",
                      },
                      [_vm._v(_vm._s(_vm.imageValidationMessage))]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _c("tip-tap-editor", {
                    attrs: {
                      label: !_vm.$language ? "About this Course" : "বিবরণ",
                      "place-holder": !_vm.$language
                        ? "Enter your course description here..."
                        : "আপনার কোর্সের বিবরণ লিখুন...",
                      "is-valid": !_vm.isDescriptionNotValid,
                      disabled: _vm.readOnly,
                      "allow-list-insertion": "",
                      "allow-table-insertion": "",
                    },
                    on: { input: _vm.handlesDescriptionWarning },
                    model: {
                      value: _vm.formData.course_description,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "course_description", $$v)
                      },
                      expression: "formData.course_description",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "pt-2 px-3",
                      class: [
                        "v-text-field__details",
                        _vm.showhideDescriptionWarningMessage,
                      ],
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "v-messages theme--light error--text",
                          attrs: { role: "alert" },
                        },
                        [
                          _c("div", { staticClass: "v-messages__wrapper" }, [
                            _c("div", { staticClass: "v-messages__message" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language
                                      ? "Need more than 40 and less than 60000 characters"
                                      : "৪০ থেকে ৬০০০০ অক্ষরের মধ্যে সীমাবদ্ধ থাকুন"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  attrs: { cols: "1" },
                },
                [
                  _c("v-icon", { attrs: { size: "30" } }, [
                    _vm._v("mdi-video"),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "9" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      "hide-details": "",
                      readonly: _vm.readOnly,
                      label: !_vm.$language ? "About Video" : "কোর্সের ভিডিও",
                      placeholder: !_vm.$language
                        ? "Add a video link for the about section"
                        : "একটি ভিডিও লিঙ্ক যোগ করুন",
                    },
                    model: {
                      value: _vm.formData.about_video,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "about_video", $$v)
                      },
                      expression: "formData.about_video",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-4" },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  attrs: { cols: "1" },
                },
                [
                  _c("v-icon", { attrs: { size: "30", color: "#0099dc" } }, [
                    _vm._v("mdi-facebook"),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "9" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      "hide-details": "",
                      readonly: _vm.readOnly,
                      label: !_vm.$language ? "Facebook Group" : "ফেসবুক গ্রুপ",
                      placeholder: !_vm.$language
                        ? "Add a link to your facebook group"
                        : "ফেসবুক গ্রুপের লিঙ্ক যোগ করুন",
                    },
                    model: {
                      value: _vm.formData.facebook_group,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "facebook_group", $$v)
                      },
                      expression: "formData.facebook_group",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _c("tip-tap-editor", {
                    attrs: {
                      label: !_vm.$language
                        ? "Course Outline"
                        : "কোর্স সিলেবাস",
                      "place-holder": !_vm.$language
                        ? "Write your Course Outline here"
                        : "কোর্স সিলেবাস লিখুন",
                      disabled: _vm.readOnly,
                      "is-valid": !_vm.isSyllabusNotValid,
                      "allow-list-insertion": "",
                      "allow-table-insertion": "",
                    },
                    on: { input: _vm.handlesSyllabusWarning },
                    model: {
                      value: _vm.formData.syllabus,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "syllabus", $$v)
                      },
                      expression: "formData.syllabus",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "pt-2 px-3",
                      class: [
                        "v-text-field__details",
                        _vm.showhideSyllabusWarningMessage,
                      ],
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "v-messages theme--light error--text",
                          attrs: { role: "alert" },
                        },
                        [
                          _c("div", { staticClass: "v-messages__wrapper" }, [
                            _c("div", { staticClass: "v-messages__message" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language
                                      ? "Need more than 40 and less than 60000 characters"
                                      : "৪০ থেকে ৬০০০০ অক্ষরের মধ্যে সীমাবদ্ধ থাকুন"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-8 mb-2" },
            [
              _c(
                "v-col",
                { staticStyle: { position: "relative" }, attrs: { cols: "5" } },
                [
                  _c("v-select", {
                    attrs: {
                      readonly: _vm.readOnly,
                      rules: _vm.rules.category,
                      outlined: "",
                      label: !_vm.$language
                        ? "Course Category.."
                        : "কোর্স ক্যাটাগরি",
                      items: _vm.categoryList,
                      "item-value": "en",
                      "item-text": !_vm.$language ? "en" : "bn",
                    },
                    on: { change: _vm.clearSubcategory },
                    model: {
                      value: _vm.formData.category,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "category", $$v)
                      },
                      expression: "formData.category",
                    },
                  }),
                ],
                1
              ),
              !(_vm.formData.category == "Others")
                ? _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-select", {
                        attrs: {
                          readonly: _vm.readOnly,
                          rules: _vm.rules.subcategories,
                          outlined: "",
                          multiple: "",
                          label: !_vm.$language
                            ? "Course Sub-Category"
                            : "কোর্স সাব ক্যাটাগরি",
                          items: _vm.subCategoryList,
                          "item-value": "en",
                          "item-text": !_vm.$language ? "en" : "bn",
                        },
                        model: {
                          value: _vm.formData.subcategories,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "subcategories", $$v)
                          },
                          expression: "formData.subcategories",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.formData.category == "Others",
                  expression: "formData.category == 'Others'",
                },
              ],
            },
            [
              _c(
                "v-col",
                { staticClass: "pt-0 pb-5", attrs: { cols: "5" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.readOnly,
                      outlined: "",
                      "hide-details": "",
                      label: !_vm.$language
                        ? "Describe Category"
                        : "ক্যাটাগরির বিবরণ",
                    },
                    model: {
                      value: _vm.formData.additionalSubCategory,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "additionalSubCategory", $$v)
                      },
                      expression: "formData.additionalSubCategory",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "span",
                    {
                      style: `transform: translateY(-24px) scale(0.75);
            font-size: 16px;
            color: ${_vm.courseMaterialStyle};
            background-color: white;
            position: absolute;
            margin-top: 12px;
            margin-left: 6px;
            z-index: 1;`,
                    },
                    [
                      _vm._v(
                        _vm._s(
                          !_vm.$language
                            ? "This Course Includes"
                            : "এই কোর্সে আছে"
                        )
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      style: `transform: translateY(-24px) scale(0.75);
            font-size: 16px;
            color: ${_vm.courseMaterialStyle};
            background-color: white;
            position: absolute;
            margin-top: 12px;
            margin-left: 6px;
            z-index: 1;`,
                    },
                    [
                      _vm._v(
                        _vm._s(
                          !_vm.$language
                            ? "This Course Includes"
                            : "এই কোর্সে আছে"
                        )
                      ),
                    ]
                  ),
                  _c(
                    "v-card",
                    {
                      style: `border-color: ${_vm.courseMaterialStyle}; padding-top: 3%; padding-left: 3%`,
                      attrs: {
                        flat: "",
                        outlined: "",
                        "min-height": "38vh",
                        width: "83%",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticStyle: {
                            "padding-left": "30px",
                            "max-height": "40px",
                          },
                        },
                        [
                          _c("v-col", { staticStyle: { "max-width": "13%" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.formData.course_materials
                                      .video_lectures,
                                  expression:
                                    "formData.course_materials.video_lectures",
                                },
                              ],
                              staticClass: "course-materials-box",
                              class: { inert: _vm.readOnly },
                              staticStyle: {
                                width: "70px",
                                "min-height": "35px",
                              },
                              attrs: {
                                id: "mk-course-details-form-page-input-video-lecture-count",
                                placeholder: "00",
                                type: "number",
                                min: "0",
                              },
                              domProps: {
                                value:
                                  _vm.formData.course_materials.video_lectures,
                              },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formData.course_materials,
                                      "video_lectures",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.adjustInput(
                                      "mk-course-details-form-page-input-video-lecture-count"
                                    )
                                  },
                                ],
                              },
                            }),
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 pt-5 course-materials-text",
                              staticStyle: { "max-width": "12%", left: "30px" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language
                                      ? "Video Lectures"
                                      : "ভিডিও লেকচার"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("v-col", { attrs: { cols: "1" } }),
                          _c("v-col", { staticStyle: { "max-width": "13%" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.formData.course_materials.live_classes,
                                  expression:
                                    "formData.course_materials.live_classes",
                                },
                              ],
                              staticClass: "course-materials-box",
                              class: { inert: _vm.readOnly },
                              staticStyle: {
                                width: "70px",
                                "min-height": "35px",
                              },
                              attrs: {
                                id: "mk-course-details-form-page-input-live-class-count",
                                placeholder: "00",
                                type: "number",
                                min: "0",
                              },
                              domProps: {
                                value:
                                  _vm.formData.course_materials.live_classes,
                              },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formData.course_materials,
                                      "live_classes",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.adjustInput(
                                      "mk-course-details-form-page-input-live-class-count"
                                    )
                                  },
                                ],
                              },
                            }),
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 pt-5 course-materials-text",
                              staticStyle: { "max-width": "12%", left: "30px" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language
                                      ? "Live Class(es)"
                                      : "লাইভ ক্লাস"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("v-col", { attrs: { cols: "1" } }),
                          _c("v-col", { staticStyle: { "max-width": "13%" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formData.course_materials.quizzes,
                                  expression:
                                    "formData.course_materials.quizzes",
                                },
                              ],
                              staticClass: "course-materials-box",
                              class: { inert: _vm.readOnly },
                              staticStyle: {
                                width: "70px",
                                "min-height": "35px",
                              },
                              attrs: {
                                id: "mk-course-details-form-page-input-quiz-count",
                                placeholder: "00",
                                type: "number",
                                min: "0",
                              },
                              domProps: {
                                value: _vm.formData.course_materials.quizzes,
                              },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formData.course_materials,
                                      "quizzes",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.adjustInput(
                                      "mk-course-details-form-page-input-quiz-count"
                                    )
                                  },
                                ],
                              },
                            }),
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 pt-5 course-materials-text",
                              staticStyle: { "max-width": "12%", left: "30px" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(!_vm.$language ? "Quiz(es)" : "কুইজ") +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4",
                          staticStyle: {
                            "padding-left": "30px",
                            "max-height": "40px",
                          },
                        },
                        [
                          _c("v-col", { staticStyle: { "max-width": "13%" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.formData.course_materials.lecture_notes,
                                  expression:
                                    "formData.course_materials.lecture_notes",
                                },
                              ],
                              staticClass: "course-materials-box",
                              class: { inert: _vm.readOnly },
                              staticStyle: {
                                width: "70px",
                                "min-height": "35px",
                              },
                              attrs: {
                                id: "mk-course-details-form-page-input-lecture-count",
                                placeholder: "00",
                                type: "number",
                                min: "0",
                              },
                              domProps: {
                                value:
                                  _vm.formData.course_materials.lecture_notes,
                              },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formData.course_materials,
                                      "lecture_notes",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.adjustInput(
                                      "mk-course-details-form-page-input-lecture-count"
                                    )
                                  },
                                ],
                              },
                            }),
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 pt-5 course-materials-text",
                              staticStyle: { "max-width": "12%", left: "30px" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language
                                      ? "Lecture Note(s)"
                                      : "লেকচার নোট"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("v-col", { attrs: { cols: "1" } }),
                          _c("v-col", { staticStyle: { "max-width": "13%" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formData.course_materials.projects,
                                  expression:
                                    "formData.course_materials.projects",
                                },
                              ],
                              staticClass: "course-materials-box",
                              class: { inert: _vm.readOnly },
                              staticStyle: {
                                width: "70px",
                                "min-height": "35px",
                              },
                              attrs: {
                                id: "mk-course-details-form-page-input-project-count",
                                placeholder: "00",
                                type: "number",
                                min: "0",
                              },
                              domProps: {
                                value: _vm.formData.course_materials.projects,
                              },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formData.course_materials,
                                      "projects",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.adjustInput(
                                      "mk-course-details-form-page-input-project-count"
                                    )
                                  },
                                ],
                              },
                            }),
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 pt-5 course-materials-text",
                              staticStyle: { "max-width": "12%", left: "30px" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language ? "Project(s)" : "প্রজেক্ট"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("v-col", { attrs: { cols: "1" } }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4",
                          staticStyle: { "padding-left": "30px" },
                        },
                        [
                          _c("v-col", { staticStyle: { "max-width": "13%" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.formData.course_materials.assignments,
                                  expression:
                                    "formData.course_materials.assignments",
                                },
                              ],
                              staticClass: "course-materials-box",
                              class: { inert: _vm.readOnly },
                              staticStyle: {
                                width: "70px",
                                "min-height": "35px",
                              },
                              attrs: {
                                id: "mk-course-details-form-page-input-assignment-count",
                                placeholder: "00",
                                type: "number",
                                min: "0",
                              },
                              domProps: {
                                value:
                                  _vm.formData.course_materials.assignments,
                              },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formData.course_materials,
                                      "assignments",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.adjustInput(
                                      "mk-course-details-form-page-input-assignment-count"
                                    )
                                  },
                                ],
                              },
                            }),
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 pt-5 course-materials-text",
                              staticStyle: { "max-width": "12%", left: "30px" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language
                                      ? "Assignment(s)"
                                      : "এসাইনমেন্ট"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("v-col", { attrs: { cols: "1" } }),
                          _c("v-col", { staticStyle: { "max-width": "13%" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formData.course_materials.exams,
                                  expression: "formData.course_materials.exams",
                                },
                              ],
                              staticClass: "course-materials-box",
                              class: { inert: _vm.readOnly },
                              staticStyle: {
                                width: "70px",
                                "min-height": "35px",
                              },
                              attrs: {
                                id: "mk-course-details-form-page-input-exam-count",
                                placeholder: "00",
                                type: "number",
                                min: "0",
                              },
                              domProps: {
                                value: _vm.formData.course_materials.exams,
                              },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formData.course_materials,
                                      "exams",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.adjustInput(
                                      "mk-course-details-form-page-input-exam-count"
                                    )
                                  },
                                ],
                              },
                            }),
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 pt-5 course-materials-text",
                              staticStyle: { "max-width": "12%", left: "30px" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language ? "Exam(s)" : "পরীক্ষা"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.shouldShowCourseMaterialAlert &&
                  !_vm.courseMaterialValid &&
                  _vm.formData.course_type == "Public"
                    ? _c(
                        "span",
                        {
                          staticClass: "pt-2 px-3",
                          staticStyle: {
                            "font-size": "12px",
                            color: "#ff5252",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              !_vm.$language
                                ? "If course is public, course materials count must be greater than 0"
                                : "পাবলিক কোর্সের জন্য কোর্স সংখ্যা নূন্যতম ১ হতে হবে"
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }